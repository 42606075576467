import type { MetaMaskInpageProvider } from '@metamask/providers';

import { defaultSnapOrigin } from '../config';
import type { GetSnapsResponse, Snap } from '../types';
import { createIdPackage } from './qubic';

/**
 * Get the installed snaps in MetaMask.
 *
 * @param provider - The MetaMask inpage provider.
 * @returns The snaps installed in MetaMask.
 */

export const getSnaps = async (
  provider?: MetaMaskInpageProvider,
): Promise<GetSnapsResponse> =>
  (await (provider ?? window.ethereum).request({
    method: 'wallet_getSnaps',
  })) as unknown as GetSnapsResponse;

/**
 * Connect a snap to MetaMask.
 *
 * @param snapId - The ID of the snap.
 * @param params - The params to pass with the snap to connect.
 */

export const connectSnap = async (
  snapId: string = defaultSnapOrigin,
  params: Record<'version' | string, unknown> = {},
) => {
  await window.ethereum.request({
    method: 'wallet_requestSnaps',
    params: {
      [snapId]: params,
    },
  });
};

/**
 * Get the snap from MetaMask.
 *
 * @param version - The version of the snap to install (optional).
 * @returns The snap object returned by the extension.
 */

export const getSnap = async (version?: string): Promise<Snap | undefined> => {
  try {
    const snaps = await getSnaps();

    return Object.values(snaps).find(
      (snap) =>
        snap.id === defaultSnapOrigin && (!version || snap.version === version),
    );
  } catch (error) {
    console.error('Failed to obtain installed snap', error);
    return undefined;
  }
};

/**
 * Get the snap info from MetaMask.
 *
 * @returns The snap info object returned by the extension.
 */

export const getInfo = async (): Promise<Record<string, any>> => {
  // await clearInfo();
  const result = await window.ethereum.request({
    method: 'wallet_invokeSnap',
    params: { snapId: defaultSnapOrigin, request: { method: 'get_info' } },
  });

  // Ensure the result is an object before returning.
  return typeof result === 'object' && result !== null ? result : {};
};

/**
 * Set the snap info in MetaMask.
 *
 * @param data - The data to set in the snap.
 */

export const setInfo = async (data: any) => {
  await window.ethereum.request({
    method: 'wallet_invokeSnap',
    params: {
      snapId: defaultSnapOrigin,
      request: { method: 'set_info', params: data },
    },
  });
};

/**
 * Clear the snap info in MetaMask.
 */

export const clearInfo = async () => {
  await window.ethereum.request({
    method: 'wallet_invokeSnap',
    params: { snapId: defaultSnapOrigin, request: { method: 'clear_info' } },
  });
};

/**
 * Generate a random seed for the snap.
 * @description Generates a random seed for qubic wallet.
 * @returns A random seed.
 */

const generateSeed = () => {
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  let seed = '';
  for (let i = 0; i < 55; i++) {
    seed += letters.charAt(Math.floor(Math.random() * letters.length));
  }

  return seed;
};

/**
 * Set the initial info for the snap in MetaMask.
 * @description Sets the initial info for the snap in MetaMask.
 * @returns A promise that resolves to the initial info or an error message.
 */

export const createNewWallet = async (
  walletName: string,
  randomSeed?: string,
) => {
  let seed = randomSeed;
  if (!seed) {
    seed = generateSeed();
  }
  const idPackage = await createIdPackage(seed);
  const currentInfo = await getInfo();
  await setInfo({
    ...currentInfo,
    idPackages: [
      ...(currentInfo.idPackages || []),
      { ...idPackage, privateKeyString: randomSeed, name: walletName },
    ],
  });
};

/**
 * Get the initial info for the snap from MetaMask.
 * @description Gets the initial info for the snap from MetaMask.
 * @returns A promise that resolves to the initial info or an error message.
 */

export const isLocalSnap = (snapId: string) => snapId.startsWith('local:');
