import { Dialog, Transition } from '@headlessui/react';
import { ArrowRightIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';

import {
  CancelButton,
  CreateWalletButton,
  SendTransactionButton,
} from './Buttons';
import { Input } from './Input';

const Modal = ({
  children,
  open,
  onClose,
}: {
  children: React.ReactNode;
  open: boolean;
  onClose: (open: boolean) => void;
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-slate-950 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[70vh] sm:p-6">
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const SendTransactionModal = ({ open, onClose, selectedIdPackage }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div>
        <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-pink-100">
          <ArrowRightIcon
            className="h-12 w-12 text-pink-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-2xl font-semibold leading-6 text-gray-100"
          >
            Send a new transaction{' '}
            {selectedIdPackage && `from ${selectedIdPackage.name}`}
          </Dialog.Title>
          <div className="mt-4">
            <p className="text-xl text-gray-500">
              You are about to send a new transaction to the QUBIC network.
              Please review the details below before proceeding.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-4 grid grid-cols-1 lg:grid-cols-1 gap-x-6 gap-y-4">
        <Input
          label="To Wallet"
          id="to-wallet"
          type="text"
          value={''}
          onChange={() => {}}
        />
      </div>
      <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-4">
        <Input
          label="Amount"
          id="amount"
          type="text"
          value=""
          onChange={() => {}}
        />
        <Input
          label="Tick"
          id="tick"
          type="text"
          value=""
          onChange={() => {}}
        />
      </div>

      <div className="flex mt-6 lg:mt-12 mx-12 justify-center gap-x-12">
        <SendTransactionButton onClick={() => onClose(false)} />
        <CancelButton onClick={() => onClose(false)} />
      </div>
    </Modal>
  );
};

export const ViewWalletModal = ({
  open,
  onClose,
  selectedIdPackage,
}: {
  open: boolean;
  onClose: (open: boolean) => void;
  selectedIdPackage: any;
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div>
        <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-pink-100">
          <ArrowRightIcon
            className="h-12 w-12 text-pink-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-2xl font-semibold leading-6 text-gray-100"
          >
            Your wallet {selectedIdPackage && ` ${selectedIdPackage?.name}`}
          </Dialog.Title>
          <div className="mt-4">
            <p className="text-xl text-gray-500">
              Below are your current wallet details. Please store your private
              key in a save environment.
            </p>
          </div>
        </div>
        <div className="mt-8 grid grid-cols-1 lg:grid-cols-1 gap-x-6 gap-y-4">
          <Input
            label="Wallet Address"
            id="wallet-address"
            type="text"
            disabled
            value={selectedIdPackage?.publicId}
            onChange={() => {}}
          />

          <Input
            label="Private Key"
            id="private-key"
            type="text"
            disabled
            value={selectedIdPackage?.privateKeyString}
            onChange={() => {}}
          />
        </div>
      </div>
      <div className="flex mt-6 lg:mt-12 mx-12 justify-center gap-x-12">
        <CancelButton onClick={() => onClose(false)} />
      </div>
    </Modal>
  );
};

export const CreateWalletModal = ({
  open,
  onClose,
  canClose,
  handleCreateWallet,
  walletInfo,
  handleInputChange,
}: {
  open: boolean;
  onClose: (open: boolean) => void;
  canClose: boolean;
  handleCreateWallet: any;
  walletInfo: any;
  handleInputChange: any;
}) => {
  const [showSeed, setShowSeed] = useState(false);

  return (
    <Modal open={open} onClose={canClose ? () => onClose(false) : () => {}}>
      <div>
        <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-pink-100">
          <PlusCircleIcon
            className="h-12 w-12 text-pink-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title
            as="h3"
            className="text-2xl font-semibold leading-6 text-gray-100"
          >
            Create a new wallet
          </Dialog.Title>
          <div className="mt-4">
            <p className="text-xl text-gray-500">
              You are about to create a new wallet. Please store your seed in a
              save environment.
            </p>
            <p className="text-xl text-gray-500">
              If you already have a seed, you can import it by clicking
              <span
                className="text-pink-600 cursor-pointer"
                onClick={() => setShowSeed(!showSeed)}
              >
                {' '}
                Import seed
              </span>
              .
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 grid grid-cols-1 lg:grid-cols-1 gap-x-6 gap-y-4">
        <Input
          label="Wallet Name"
          id="name"
          type="text"
          value={walletInfo.name}
          onChange={handleInputChange}
        />
        {showSeed && (
          <Input
            label="Seed"
            id="seed"
            type="text"
            value={walletInfo.seed}
            onChange={handleInputChange}
          />
        )}
      </div>
      <div className="flex mt-6 lg:mt-12 mx-12 justify-center gap-x-12">
        <CreateWalletButton onClick={handleCreateWallet} />
        {canClose && <CancelButton onClick={() => onClose(false)} />}
      </div>
    </Modal>
  );
};
