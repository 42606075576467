import { ReactComponent as MetaMaskFox } from '../assets/metamask_fox.svg';
import { MetaMask } from './MetaMask';
import { PoweredBy } from './PoweredBy';

const FooterWrapper = ({ children }: { children: React.ReactNode }) => (
  <footer className="flex flex-row items-center justify-center pt-6 pb-6 border-t border-slate-900 bg-slate-950">
    {children}
  </footer>
);

const PoweredByButton = ({
  href,
  children,
  target,
}: {
  href: string;
  target?: string;
  children: React.ReactNode;
}) => (
  <a
    href={href}
    target={target}
    className="flex flex-row items-center justify-center p-3 rounded-md shadow bg-slate-900"
  >
    {children}
  </a>
);

const PoweredByContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="flex flex-col ml-4">{children}</div>
);

export const Footer = () => {
  return (
    <FooterWrapper>
      <PoweredByButton href="https://docs.metamask.io/" target="_blank">
        <MetaMaskFox />
        <PoweredByContainer>
          <PoweredBy color="white" />
          <MetaMask color="white" />
        </PoweredByContainer>
      </PoweredByButton>
    </FooterWrapper>
  );
};
