export const Input = ({
  label,
  id,
  type,
  disabled,
  value,
  onChange,
}: {
  label: string;
  id: string;
  type: string;
  disabled?: boolean;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        className="block text-xl font-medium leading-6 text-gray-100"
      >
        {label}
      </label>
      <div className="mt-2 text-[10vh]">
        <input
          type={type}
          name={id}
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          className="block w-full rounded-md border-0 py-2 px-4 text-gray-100 text-2xl shadow-sm ring-1 ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none focus:ring-indigo-600 disabled:cursor-normal bg-gray-800 disabled:bg-gray-900 disabled:text-gray-500 disabled:ring-gray-800 text-md sm:leading-6"
          placeholder={label}
        />
      </div>
    </div>
  );
};
