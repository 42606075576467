export const SnapLogo = ({
  size,
}: {
  color?: string | undefined;
  size: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 200 200"
    version="1.1"
  >
    <path
      d="M 62.500 29.414 C 61.400 30.135, 52.400 35.363, 42.500 41.034 C 32.600 46.705, 24.133 51.656, 23.684 52.038 C 22.924 52.684, 25.473 54.404, 35.500 60.008 C 37.700 61.238, 45.159 65.564, 52.076 69.622 C 58.993 73.680, 65.068 77, 65.576 77 C 66.116 77, 66.500 66.820, 66.500 52.500 C 66.500 36.798, 66.141 28.019, 65.500 28.052 C 64.950 28.081, 63.600 28.694, 62.500 29.414 M 22.010 103.250 L 22.020 148.500 47.173 163 C 61.007 170.975, 77.934 180.762, 84.789 184.750 C 91.644 188.738, 97.650 192, 98.136 192 C 98.639 192, 98.908 172.744, 98.760 147.344 L 98.500 102.689 87.500 95.975 C 62.884 80.952, 23.885 58, 22.974 58 C 22.356 58, 22.004 74.555, 22.010 103.250"
      stroke="none"
      fill="#4298ca"
      fill-rule="evenodd"
    />
    <path
      d="M 82.960 17.625 L 66.421 27.187 66.389 52.500 L 66.357 77.814 82.377 87.157 C 91.188 92.296, 99.011 96.651, 99.762 96.835 C 100.512 97.020, 105.485 94.552, 110.813 91.350 C 116.141 88.148, 121.400 85.036, 122.500 84.433 C 123.600 83.831, 132.600 78.478, 142.500 72.537 C 152.400 66.597, 162.075 60.855, 164 59.777 C 165.925 58.700, 169.667 56.443, 172.315 54.763 L 177.130 51.708 162.987 43.354 C 155.209 38.759, 148.685 35, 148.491 35 C 148.296 35, 142.436 31.625, 135.467 27.500 C 128.499 23.375, 122.660 20, 122.492 20 C 122.324 20, 117.639 17.300, 112.081 14 C 106.524 10.700, 101.419 8.014, 100.738 8.031 C 100.057 8.048, 92.057 12.365, 82.960 17.625 M 150.500 73.117 C 136.750 81.354, 120.100 91.368, 113.500 95.370 L 101.500 102.648 101.240 147.324 C 101.092 172.736, 101.361 192, 101.864 192 C 102.350 192, 108.356 188.737, 115.211 184.750 C 122.066 180.762, 138.993 170.975, 152.827 163 L 177.980 148.500 177.990 103.250 C 177.998 67.674, 177.733 58.015, 176.750 58.070 C 176.063 58.109, 164.250 64.880, 150.500 73.117"
      stroke="none"
      fill="#2e3985"
      fill-rule="evenodd"
    />
  </svg>
);
