import {
  PaperAirplaneIcon,
  EyeIcon,
  XCircleIcon,
  PlusCircleIcon,
} from '@heroicons/react/20/solid';
import type { ComponentProps } from 'react';

import { ReactComponent as MetaMaskFox } from '../assets/metamask_fox.svg';
import type { MetamaskState } from '../hooks';
import { shouldDisplayReconnectButton } from '../utils';

const Link = ({
  children,
  href,
  target,
}: {
  children: React.ReactNode;
  href?: string;
  target?: string;
}) => (
  <a
    href={href}
    target={target}
    className="flex flex-start items-center justify-center font-bold text-white border border-transparent bg-blue-500 rounded-md text-sm p-4 hover:bg-transparent hover:text-blue-500 hover:border-blue-500 transition-all duration-200 ease-in-out w-full sm:w-auto"
  >
    {children}
  </a>
);

const Button = ({
  children,
  onClick,
}: {
  children?: React.ReactNode;
  onClick(): unknown;
}) => (
  <button
    onClick={onClick}
    className="flex flex-start items-center justify-center mt-auto text-sm text-white px-2 py-2 w-full sm:w-auto bg-pink-600 rounded-lg hover:bg-transparent border border-pink-600 hover:border-pink-700 hover:text-gray-100"
  >
    {children}
  </button>
);

const ButtonSecondary = ({
  children,
  onClick,
}: {
  children?: React.ReactNode;
  onClick(): unknown;
}) => (
  <button
    onClick={onClick}
    className="flex flex-start items-center justify-center mt-auto text-sm text-white px-4 py-2 w-full sm:w-auto bg-red-600 rounded-lg hover:bg-transparent border border-red-600 hover:border-red-700 hover:text-gray-100"
  >
    {children}
  </button>
);

const ButtonText = ({ children }: { children: React.ReactNode }) => (
  <span className="ml-4 text-2xl font-medium">{children}</span>
);

const ConnectedContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="flex flex-start items-center justify-center font-bold text-white border border-transparent bg-blue-500 rounded-md text-sm p-5">
    {children}
  </div>
);

const ConnectedIndicator = () => (
  <div className="w-2.5 h-2.5 bg-green-500 rounded-full"></div>
);

export const InstallFlaskButton = () => (
  <Link href="https://metamask.io/" target="_blank">
    <MetaMaskFox />
    <ButtonText>Install MetaMask</ButtonText>
  </Link>
);

export const ConnectButton = ({ onClick }: ComponentProps<typeof Button>) => {
  return (
    <Button onClick={onClick}>
      <MetaMaskFox />
      <ButtonText>Connect</ButtonText>
    </Button>
  );
};

export const ReconnectButton = ({ onClick }: ComponentProps<typeof Button>) => {
  return (
    <Button onClick={onClick}>
      <MetaMaskFox />
      <ButtonText>Reconnect</ButtonText>
    </Button>
  );
};

export const CancelButton = ({ onClick }: ComponentProps<typeof Button>) => {
  return (
    <ButtonSecondary onClick={onClick}>
      <XCircleIcon className="h-8 w-8" aria-hidden="true" />
      <ButtonText>Go back</ButtonText>
    </ButtonSecondary>
  );
};

export const SendTransactionButton = ({
  onClick,
}: ComponentProps<typeof Button>) => {
  return (
    <Button onClick={onClick}>
      <PaperAirplaneIcon className="h-8 w-8" aria-hidden="true" />
      <ButtonText>
        Send <span className="hidden md:inline-block">Transaction</span>
      </ButtonText>
    </Button>
  );
};

export const ViewWalletButton = ({
  onClick,
}: ComponentProps<typeof Button>) => {
  return (
    <Button onClick={onClick}>
      <EyeIcon className="h-8 w-8" aria-hidden="true" />
      <ButtonText>
        View <span className="hidden md:inline-block">wallet</span>
      </ButtonText>
    </Button>
  );
};

export const CreateWalletButton = ({
  onClick,
}: ComponentProps<typeof Button>) => {
  return (
    <Button onClick={onClick}>
      <PlusCircleIcon className="h-8 w-8" aria-hidden="true" />
      <ButtonText>
        Create <span className="hidden md:inline-block">wallet</span>
      </ButtonText>
    </Button>
  );
};

export const SendHelloButton = (props: ComponentProps<typeof Button>) => {
  return (
    <Button {...props}>
      <ButtonText>Send message</ButtonText>
    </Button>
  );
};

export const HeaderButtons = ({
  state,
  onConnectClick,
}: {
  state: MetamaskState;
  onConnectClick(): unknown;
}) => {
  if (!state.isFlask && !state.installedSnap) {
    return <InstallFlaskButton />;
  }

  if (!state.installedSnap) {
    return <ConnectButton onClick={onConnectClick} />;
  }

  if (shouldDisplayReconnectButton(state.installedSnap)) {
    return <ReconnectButton onClick={onConnectClick} />;
  }

  return (
    <ConnectedContainer>
      <ConnectedIndicator />
      <ButtonText>Connected</ButtonText>
    </ConnectedContainer>
  );
};
