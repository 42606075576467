import { QubicPackageType } from 'qubic-ts-library/dist/qubic-communication/QubicPackageType';
import { RequestResponseHeader } from 'qubic-ts-library/dist/qubic-communication/RequestResponseHeader';
import { PublicKey } from 'qubic-ts-library/dist/qubic-types/PublicKey';
import { QubicTransaction } from 'qubic-ts-library/dist/qubic-types/QubicTransaction';
import { QubicConnector } from 'qubic-ts-library/dist/QubicConnector';
import { QubicHelper } from 'qubic-ts-library/dist/qubicHelper';
import { QubicPackageBuilder } from 'qubic-ts-library/dist/QubicPackageBuilder';

const helper = new QubicHelper();

const qubicConnector = new QubicConnector(null);
const peerAddress = '212.51.150.253';
let connected = false;

qubicConnector.onPeerConnected = () => {
  console.log('Peer connected');
  connected = true;
};

qubicConnector.onPeerDisconnected = () => {
  console.log('Peer disconnected');
  connected = false;
};

qubicConnector.onReady = () => {
  qubicConnector.connect(peerAddress);
};

qubicConnector.start();

const waitForPeerConnection = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (connected) {
      resolve();
    }
    const timeout = setTimeout(() => {
      reject(new Error('Connection timeout'));
    }, 30000);

    qubicConnector.onPeerConnected = () => {
      clearTimeout(timeout);
      console.log('Peer connected');
      resolve();
    };
    qubicConnector.connect(peerAddress);
  });
};

/**
 * Requests the balance for a given public key from the Qubic network.
 *
 * @param publicKeyString - The public key as a string.
 * @returns A promise that resolves to the balance or an error message.
 * @description Requests the balance for a given public key from the Qubic network.
 */

export const getBalance = async (publicKeyString: string): Promise<string> => {
  try {
    await waitForPeerConnection();
    return new Promise((resolve, reject) => {
      const publicKey = new PublicKey(publicKeyString);

      qubicConnector.onBalance = (response) => {
        const entity = response.getEntity();
        console.log('Balance response:', entity);
        const incomingAmount = entity.getIncomingAmount();
        const outgoingAmount = entity.getOutgoingAmount();

        if (incomingAmount && outgoingAmount) {
          resolve(entity.getBalance().toString());
        } else {
          reject(new Error('Balance information is unavailable.'));
        }
      };
      qubicConnector.requestBalance(publicKey);
    });
  } catch (error) {
    console.error('Error waiting for peer connection:', error);
    throw error;
  }
};

/**
 * Sends a transaction to the Qubic network.
 *
 * @param sourceSeed - The seed of the source account.
 * @param sourcePublicKeyString - The public key of the source account as a string.
 * @param destinationPublicKey - The public key of the destination account as a string.
 * @param amount - The amount to send.
 * @param tick - The tick at which to send the transaction.
 * @returns A promise that resolves to the transaction or an error message.
 * @description Sends a transaction to the Qubic network.
 */

export const sendTransaction = async (
  sourceSeed: string,
  sourcePublicKey: string,
  destinationPublicKey: string,
  amount: number,
  tick: number,
) => {
  const sourceKey = new PublicKey(sourcePublicKey);
  const destinationKey = new PublicKey(destinationPublicKey);

  const tx = new QubicTransaction()
    .setSourcePublicKey(sourceKey)
    .setDestinationPublicKey(destinationKey)
    .setAmount(amount)
    .setTick(tick);

  await tx.build(sourceSeed);

  const header = new RequestResponseHeader(
    QubicPackageType.BROADCAST_TRANSACTION,
    tx.getPackageSize(),
  );

  const builder = new QubicPackageBuilder(header.getSize());
  builder.add(header);
  builder.add(tx);

  const data = builder.getData();
  console.log(tx, data);
  return tx;
};

/**
 *
 * @returns A promise that resolves to the current tick or an error message.
 * @description Requests the current tick from the Qubic network.
 */

export const getCurrentTick = async () => {
  return new Promise((resolve) => {
    qubicConnector.onTick = (response) => {
      resolve(response);
    };
  });
};

/**
 * Connects to a Qubic peer.
 *
 * @description Connects to a Qubic peer.
 */

export const connect = () => {
  qubicConnector.connect(peerAddress);
};

export const createIdPackage = async (publicKeyString: string) => {
  const idPackage = helper.createIdPackage(publicKeyString);
  return idPackage;
};
