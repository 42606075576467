import { Dialog, Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Bars3Icon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

import { SnapLogo } from './SnapLogo';

const classNames = (...classes: any[]) => {
  return classes.filter(Boolean).join(' ');
};

export const Header = ({
  currentInfo,
  selectedIdPackage,
  setSelectedIdPackage,
  setCreateWalletModalOpen,
}: {
  currentInfo: any;
  selectedIdPackage: any;
  setSelectedIdPackage: any;
  setCreateWalletModalOpen: any;
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-slate-950">
      <nav
        className="mx-auto flex max-w-full items-center justify-between p-6 lg:px-8"
        aria-label="Navigation"
      >
        <div className="flex lg:flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Qubic Wallet</span>
            <SnapLogo size={32} />
          </a>
        </div>
        <div className="flex">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 focus:outline-none border border-gray-700 hover:border-gray-600 hover:bg-slate-900"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog
        as="div"
        className=""
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-slate-950 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Qubic Wallet</span>
              <SnapLogo size={32} />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700 outline-none focus:outline-none border border-gray-700 hover:border-gray-600 hover:bg-slate-900"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open: _open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-xl font-semibold leading-7 text-gray-200 hover:bg-gray-900 border-none focus:outline-none hover:border-none outline-none">
                        Wallets
                        <ChevronDownIcon
                          className={classNames(
                            _open ? 'rotate-180' : '',
                            'h-5 w-5 flex-none',
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {currentInfo?.idPackages.map((item: any) => (
                          <Disclosure.Button
                            key={item.publicId}
                            as="button"
                            onClick={() => setSelectedIdPackage(item)}
                            className={classNames(
                              `w-full  text-left rounded-lg py-2 pl-6 pr-3 text-lg font-semibold leading-7 text-gray-100 hover:bg-gray-900 border-none focus:outline-none hover:border-none outline-none`,
                              selectedIdPackage?.publicId === item.publicId
                                ? 'bg-gray-900 cursor-default'
                                : '',
                            )}
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                        <Disclosure.Button
                          as="button"
                          onClick={() => setCreateWalletModalOpen(true)}
                          className="w-full  text-left rounded-lg py-2 pl-6 pr-3 text-lg font-semibold leading-7 text-gray-100 hover:bg-gray-900 border-none focus:outline-none hover:border-none outline-none"
                        >
                          <div className="flex flex-row gap-2">
                            <PlusIcon className="mt-0.5 h-5 w-5 flex-none" />
                            Add Wallet
                          </div>
                        </Disclosure.Button>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <a
                  href="https://github.com/Schmandmaennje/qubicsnap"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="-mx-3 block rounded-lg px-3 py-4 text-xl font-semibold leading-7 text-gray-100 hover:bg-gray-900 border-none focus:outline-none"
                >
                  Github
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};
